.header {
  color: #424242;
  z-index: 100 !important;
}

.headerIndex {
  z-index: 100 !important;
  position: absolute;
  width: 100%;
  height: 80px;
}

.header-container {
  background: linear-gradient(-10deg, #00964d, #40cc63, #00964d);
  background-size: 250% 250%;
  animation: gradient 4s ease infinite;
  background-color: #01AF5C;
  z-index: 1 !important;
  flex-direction: row;
}

.headerUpperline {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #00ff88b7;
  z-index: 2 !important;
}

.headerUnderline {
  position: absolute;
  width: 100%;
  height: 6px;
  
  background: #1A995C;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 5 !important;
}

.logoResize {
  max-width: 600px;
  max-height: 100px;
  padding-top: 5px !important;
  padding-bottom: 2px !important;
}

.profilePicResize {
  width: 55px !important;
  height: 55px !important;
}

.rounded-circle {
  border: 2px solid #61ff89 !important;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}