.map-container {
  position: relative;
  height: 100vh;
  width: 100%;
  margin-bottom: -10px;
}

.mapboxgl-control-container {
  display: none;
}

.map {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loading {
  z-index: 1;
  height: 100%;
  right: 0;
  width: 80%;
  box-shadow: inset 2500px 0 0 0 rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 1);
}

.map-source-options {
  right: 2rem;
}

.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

h6 {
  font-weight: 900;
  font-family: "Open Sans Light", sans-serif;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

h5 {
  font-weight: 900;
  font-family: "Open Sans Light", sans-serif;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.p1 {
  font-size: 103%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

/* Marker tweaks */
.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", Sans-serif;
  padding: 0;
  width: 280px;
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #e9e9e9;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #e9e9e9;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #e9e9e9;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #e9e9e9;
}

.mapboxgl-popup-content h3 {
  background: #01af5c;
  color: #fff;
  margin: 0;
  display: block;
  padding: 5px 13px 1px 13px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -15px;
  font-size: 2.2em;
  font-family: "Teko", sans-serif;
  font-style: italic;
}

.transport-text {
  font-weight: 300!important;
  padding-left: 5px!important; 
}

.mapboxgl-popup-content h4 {
  background: #e9e9e9;
  margin: 0;
  display: block;
  padding: 10px;
  font-weight: 400;
  font-size: 1.3em;
  font-family: "Teko", sans-serif;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #91c949;
}
