@import url(//fonts.googleapis.com/css?family=Teko);
@import url(//fonts.googleapis.com/css?family=Teko);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap%27);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: none !important;
}

.map-container {
  position: relative;
  height: 100vh;
  width: 100%;
  margin-bottom: -10px;
}

.mapboxgl-control-container {
  display: none;
}

.map {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loading {
  z-index: 1;
  height: 100%;
  right: 0;
  width: 80%;
  box-shadow: inset 2500px 0 0 0 rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 1);
}

.map-source-options {
  right: 2rem;
}

.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

h6 {
  font-weight: 900;
  font-family: "Open Sans Light", sans-serif;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

h5 {
  font-weight: 900;
  font-family: "Open Sans Light", sans-serif;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.p1 {
  font-size: 103%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

/* Marker tweaks */
.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", Sans-serif;
  padding: 0;
  width: 280px;
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #e9e9e9;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #e9e9e9;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #e9e9e9;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #e9e9e9;
}

.mapboxgl-popup-content h3 {
  background: #01af5c;
  color: #fff;
  margin: 0;
  display: block;
  padding: 5px 13px 1px 13px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -15px;
  font-size: 2.2em;
  font-family: "Teko", sans-serif;
  font-style: italic;
}

.transport-text {
  font-weight: 300!important;
  padding-left: 5px!important; 
}

.mapboxgl-popup-content h4 {
  background: #e9e9e9;
  margin: 0;
  display: block;
  padding: 10px;
  font-weight: 400;
  font-size: 1.3em;
  font-family: "Teko", sans-serif;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #91c949;
}

.header {
  color: #424242;
  z-index: 100 !important;
}

.headerIndex {
  z-index: 100 !important;
  position: absolute;
  width: 100%;
  height: 80px;
}

.header-container {
  background: linear-gradient(-10deg, #00964d, #40cc63, #00964d);
  background-size: 250% 250%;
  animation: gradient 4s ease infinite;
  background-color: #01AF5C;
  z-index: 1 !important;
  flex-direction: row;
}

.headerUpperline {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #00ff88b7;
  z-index: 2 !important;
}

.headerUnderline {
  position: absolute;
  width: 100%;
  height: 6px;
  
  background: #1A995C;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 5 !important;
}

.logoResize {
  max-width: 600px;
  max-height: 100px;
  padding-top: 5px !important;
  padding-bottom: 2px !important;
}

.profilePicResize {
  width: 55px !important;
  height: 55px !important;
}

.rounded-circle {
  border: 2px solid #61ff89 !important;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
.sidebar-container {
  position: absolute;
  width: 20.1%;
  height: 100%;
  max-height: 100%;
  left: 0px;
  top: 0px;
  background: #e9e9e9;
  box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.hide-button {
  position: absolute !important;
  top: 50%;
  align-self: "center";
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  padding: 5px;
  color: #a7915f;
  background-color: white !important;
  z-index: 2;
}

.hide-button-open {
  right: 0;
  margin-right: -24px !important;
}

.hide-button-closed {
  left: 0;
  margin-left: -12px !important;
}

.meeting-header {
  position: relative;
  padding-top: 5px;
  padding-bottom: 65px;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
}

.meeting-container {
  margin-top: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-container {
  overflow-y: scroll;
  position: relative;
  margin: 0 auto;
  padding-top: 4px;
  padding-bottom: 14px;
  width: 90%;
  min-height: 30%;
  background: #f9f9f9;
  box-shadow: inset 0px 0px 12px -3px #000000;
  border-radius: 22px;
}

.people-list {
  margin: 0 auto;
  margin-top: 8px !important;
  width: 92%;
  min-height: 10%;
  background: #00d649 !important;
  box-shadow: 0px 1px 0px #6a6a6a !important;
  border-radius: 16px !important;
}

.addCard {
  position: relative;
  margin: 0 auto;
  margin-top: 8px !important;
  width: 100%;
  min-height: 10%;
  left: 0;
  background: #00d649 !important;
  box-shadow: 0px 1px 0px #6a6a6a !important;
  border-radius: 16px !important;
}

.evenCard {
  background: #6ce478 !important;
}

.oddCard {
  background: #6bbf82 !important;
}

.personTab {
  text-align: left;
  margin-top: -5px;
  margin-bottom: -12px;
  letter-spacing: 0.03em;
  font-family: Teko;
  font-style: normal;
  font-size: 27px;
  color: #006115;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(100, 100, 100, 0.479);
}

.addToList {
  text-align: center;
  margin-top: -5px;
  margin-bottom: -12px;
  letter-spacing: 0.03em;
  font-family: Teko;
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #ffffff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 255, 21, 0.192);
  text-shadow: 0px 2px 2px rgb(38, 134, 0);
}

.meeting-text {
  width: 100%;
  font-family: Teko;
  font-style: normal;
  font-size: 48px;
  /* identical to box height, or 0px */
  letter-spacing: 0.03em;
  color: #5a616e;
  text-align: center;

  text-shadow: 0px 3px 0px #f3f3f3, 0px 5px 1px #8080804b;
}

.locationPreferencesHeader {
  background-color: #5084cf;
  width: 100%;
  /* height: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-arrow-button {
  height: 30px;
}

.header-arrow-icon {
  color: white;
}

.locationUnderline {
  background: #3e6eb5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.26);
  width: 100%;
  min-height: 4px;
}

.locationPreferences {
  background: #c7dbf9;
  width: 100%;
  padding-right: 7px;
}

.preferenceText {
  font-family: Teko;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  /* line-height: 100%; */
  /* identical to box height, or 0px */
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.preferenceTextHeaders {
  margin-bottom: 0;
  font-family: Teko;
  font-style: normal;
  font-size: 30px;
  padding-left: 15px;
  text-align: left;
  letter-spacing: 0.03em;
  text-shadow: 0px 2px 3px #d0d8e6, 0 0 0 #061833d2, 0px 2px 3px #d0d8e6;
  color: #667c9d6b;
}

.checkboxText {
  color: #6d7888;
  font-family: Teko;
  font-style: normal;
  font-weight: bold;
  margin-top: -10px;
}

.locationCheckboxText {
  margin-bottom: 0;
  font-family: Teko;
  font-style: normal;
  font-size: 30px !important;
  padding-left: 15px;
  text-align: left;
  letter-spacing: 0.03em;
  text-shadow: 0px 2px 3px #c7dbf9, 0 0 0 #061833d2, 0px 2px 3px #c7dbf9;
  color: #667c9d6b;
}

.methodText {
  color: rgba(109, 120, 136, 0.78);
  font-family: Teko;
  font-style: normal;
  font-size: 20px;
  margin-left: 5px;
}

.maxTravelText {
  color: rgba(109, 120, 136, 0.78);
  font-family: Teko;
  font-style: normal;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 5px;
  text-align: center;
}

.boxFix {
  font-size: 14px;
  width: 80px;
  height: 23px;
}

.testFix {
  margin-top: -8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
  background-color: #f5f5f500 !important;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px !important;
  background-color: #f5f5f500 !important;
}

#moveUp ::-webkit-scrollbar {
  width: 5px;
  height: 10px !important;
  background-color: #c7050500 !important;
}

::-webkit-scrollbar-thumb {
  margin-top: 1em;
  border-radius: 10px;
  height: 30px !important;
  width: 20px !important;
  background-color: rgba(22, 76, 83, 0.404) !important;
}

.people-list {
  margin: 0 auto;
  margin-top: 8px !important;
  width: 92%;
  min-height: 10%;
  background: #00d649 !important;
  box-shadow: 0px 1px 0px #6a6a6a !important;
  border-radius: 16px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions-buttons {
  display: none;
}

.personTab {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-top: -5px;
  margin-bottom: -12px;
  letter-spacing: 0.03em;
  font-family: Teko;
  font-style: normal;
  font-size: 27px;
  color: #006115;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(100, 100, 100, 0.479);
}

.selected {
  border: 3px solid rgb(231, 177, 0) !important;
  background: rgb(243, 215, 59) !important;
}

.people-list:hover {
  cursor: pointer;
}

.people-list:hover > .actions-buttons {
  display: block;
}

.evenCard {
  background: #6ce478 !important;
}

.oddCard {
  background: #6bbf82 !important;
}

.addCard {
  position: relative;
  margin: 0 auto;
  margin-top: 8px !important;
  width: 100%;
  min-height: 10%;
  left: 0;
  background: #00d649 !important;
  box-shadow: 0px 1px 0px #6a6a6a !important;
  border-radius: 16px !important;
}

.addPerson {
  /* margin-top: 18%; */
  width: 78%;
}

.addPerson:hover {
  cursor: pointer;
}

.addToList {
  text-align: center;
  margin-top: -5px;
  margin-bottom: -12px;
  letter-spacing: 0.03em;
  font-family: Teko;
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #ffffff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 255, 21, 0.192);
  text-shadow: 0px 2px 2px rgb(38, 134, 0);
}

.userPreferencesHeader {
  background-color: #728196;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.26);
  width: 100%;
  margin-top: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userUnderline {
  background: #576a84;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.26);
  width: 100%;
  min-height: 4px;
}

.userPreferences {
  background: #d0d8e6;
  width: 100%;
}

.header-arrow-button {
  height: 30px;
}

.header-arrow-icon {
  color: white;
}

.AutocompletePlace {
  position: relative;
}

.AutocompletePlace-input {
  width: 358px;
  height: 32px;
  left: 31px;
  top: 637px;
  background: #ffffff;
  border-radius: 150px;
  border-color: #ffffff;
}

.AutocompletePlace-input:focus {
  border-color: #00d649;
}

textarea:focus,
input:focus {
  outline: none;
}

.AutocompletePlace-results {
  position: absolute;
  background-color: white;
  padding: 0;
  margin: 0;
  border: 1px solid #ffffff;
  z-index: 10;
}

.AutocompletePlace-items {
  list-style: none;
  border: 1px solid #ffffff;
  cursor: pointer;
  padding: 10px 10px;
}

.AutocompletePlace-items:hover {
  background-color: #0000ff11;
}

.searchbar {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  color: #576A84;
}

.date-picker-container {
  display: flex;
  justify-content: center;
}

.MuiPickerDTTabs-tabs {
  background-color: #009b51 !important;
}

.MuiPickersDay-daySelected,
.MuiPickersClockPointer-pointer,
.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockNumber-clockNumberSelected {
  background-color: #01af5c !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid #01af5c !important;
}

.PrivateTabIndicator-colorSecondary-7 {
  background-color: #00ff84 !important;
}

.MuiInput-underline:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #01af5c !important;
}

.PrivateTabIndicator-root-5 {
  height: 3px;
}

.MuiInputBase-root,
.MuiInputBase-input {
  cursor: pointer !important;
  text-align: center;
}

.results-container {
  position: absolute;
  top: 90%;
}

.results-container-shown {
  left: 100%;
}

.results-container-hidden {
  left: 0;
}

.calculateContainer {
  display: flex;
  left: 20.1%;
  top: 90.5%;
  vertical-align: bottom;
}

.calculateContainer:hover {
  cursor: pointer;
}

.calculate-button {
  background-color: rgba(0, 0, 0, 0.32);
  width: 280px;
  height: 90px;
}

.textStyle {
  color: white;
  font-family: Teko;
  font-style: italic;
  font-size: 70px;
  text-align: center;
}

.resultTextStyle {
  color: white;
  font-family: Teko;
  font-style: italic;
  font-size: 40px;
  text-align: center;
}

.resultContainer {
  margin-left: 40px;
  background-color: rgba(0, 134, 11, 0.32);
  width: 60vw !important;
  max-height: 90px;
  left: 300px;
  padding-top: 5px;
  top: 15px;
  vertical-align: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
}

.departure-times-container {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.32);
  left: 0;
  bottom: 100%;
  vertical-align: bottom;
  margin-bottom: 10px;
}

.departure-times-container:hover {
  cursor: pointer;
}

.departure-times-header {
  display: flex;
  justify-content: center;
  color: white;
  font-family: Teko;
  font-style: italic;
  font-size: 50px;
  text-align: center;
}

.header-arrow {
  color: white;
}

.user-text {
  color: white;
  font-family: Teko;
  font-weight: 300;
  font-size: 45px;
  text-align: center;
  padding: 0 35px;
}

.user-column {
  padding: 0 35px;
}

