.calculateContainer {
  display: flex;
  left: 20.1%;
  top: 90.5%;
  vertical-align: bottom;
}

.calculateContainer:hover {
  cursor: pointer;
}

.calculate-button {
  background-color: rgba(0, 0, 0, 0.32);
  width: 280px;
  height: 90px;
}

.textStyle {
  color: white;
  font-family: Teko;
  font-style: italic;
  font-size: 70px;
  text-align: center;
}

.resultTextStyle {
  color: white;
  font-family: Teko;
  font-style: italic;
  font-size: 40px;
  text-align: center;
}

.resultContainer {
  margin-left: 40px;
  background-color: rgba(0, 134, 11, 0.32);
  width: 60vw !important;
  max-height: 90px;
  left: 300px;
  padding-top: 5px;
  top: 15px;
  vertical-align: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
}

@import url(//fonts.googleapis.com/css?family=Teko);
