.userPreferencesHeader {
  background-color: #728196;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.26);
  width: 100%;
  margin-top: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userUnderline {
  background: #576a84;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.26);
  width: 100%;
  min-height: 4px;
}

.userPreferences {
  background: #d0d8e6;
  width: 100%;
}

.header-arrow-button {
  height: 30px;
}

.header-arrow-icon {
  color: white;
}
