.sidebar-container {
  position: absolute;
  width: 20.1%;
  height: 100%;
  max-height: 100%;
  left: 0px;
  top: 0px;
  background: #e9e9e9;
  box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.hide-button {
  position: absolute !important;
  top: 50%;
  align-self: "center";
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  padding: 5px;
  color: #a7915f;
  background-color: white !important;
  z-index: 2;
}

.hide-button-open {
  right: 0;
  margin-right: -24px !important;
}

.hide-button-closed {
  left: 0;
  margin-left: -12px !important;
}

.meeting-header {
  position: relative;
  padding-top: 5px;
  padding-bottom: 65px;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
}

.meeting-container {
  margin-top: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-container {
  overflow-y: scroll;
  position: relative;
  margin: 0 auto;
  padding-top: 4px;
  padding-bottom: 14px;
  width: 90%;
  min-height: 30%;
  background: #f9f9f9;
  box-shadow: inset 0px 0px 12px -3px #000000;
  border-radius: 22px;
}

.people-list {
  margin: 0 auto;
  margin-top: 8px !important;
  width: 92%;
  min-height: 10%;
  background: #00d649 !important;
  box-shadow: 0px 1px 0px #6a6a6a !important;
  border-radius: 16px !important;
}

.addCard {
  position: relative;
  margin: 0 auto;
  margin-top: 8px !important;
  width: 100%;
  min-height: 10%;
  left: 0;
  background: #00d649 !important;
  box-shadow: 0px 1px 0px #6a6a6a !important;
  border-radius: 16px !important;
}

.evenCard {
  background: #6ce478 !important;
}

.oddCard {
  background: #6bbf82 !important;
}

.personTab {
  text-align: left;
  margin-top: -5px;
  margin-bottom: -12px;
  letter-spacing: 0.03em;
  font-family: Teko;
  font-style: normal;
  font-size: 27px;
  color: #006115;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(100, 100, 100, 0.479);
}

.addToList {
  text-align: center;
  margin-top: -5px;
  margin-bottom: -12px;
  letter-spacing: 0.03em;
  font-family: Teko;
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  color: #ffffff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 255, 21, 0.192);
  text-shadow: 0px 2px 2px rgb(38, 134, 0);
}

.meeting-text {
  width: 100%;
  font-family: Teko;
  font-style: normal;
  font-size: 48px;
  /* identical to box height, or 0px */
  letter-spacing: 0.03em;
  color: #5a616e;
  text-align: center;

  text-shadow: 0px 3px 0px #f3f3f3, 0px 5px 1px #8080804b;
}

.locationPreferencesHeader {
  background-color: #5084cf;
  width: 100%;
  /* height: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-arrow-button {
  height: 30px;
}

.header-arrow-icon {
  color: white;
}

.locationUnderline {
  background: #3e6eb5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.26);
  width: 100%;
  min-height: 4px;
}

.locationPreferences {
  background: #c7dbf9;
  width: 100%;
  padding-right: 7px;
}

.preferenceText {
  font-family: Teko;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  /* line-height: 100%; */
  /* identical to box height, or 0px */
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.preferenceTextHeaders {
  margin-bottom: 0;
  font-family: Teko;
  font-style: normal;
  font-size: 30px;
  padding-left: 15px;
  text-align: left;
  letter-spacing: 0.03em;
  text-shadow: 0px 2px 3px #d0d8e6, 0 0 0 #061833d2, 0px 2px 3px #d0d8e6;
  color: #667c9d6b;
}

.checkboxText {
  color: #6d7888;
  font-family: Teko;
  font-style: normal;
  font-weight: bold;
  margin-top: -10px;
}

.locationCheckboxText {
  margin-bottom: 0;
  font-family: Teko;
  font-style: normal;
  font-size: 30px !important;
  padding-left: 15px;
  text-align: left;
  letter-spacing: 0.03em;
  text-shadow: 0px 2px 3px #c7dbf9, 0 0 0 #061833d2, 0px 2px 3px #c7dbf9;
  color: #667c9d6b;
}

.methodText {
  color: rgba(109, 120, 136, 0.78);
  font-family: Teko;
  font-style: normal;
  font-size: 20px;
  margin-left: 5px;
}

.maxTravelText {
  color: rgba(109, 120, 136, 0.78);
  font-family: Teko;
  font-style: normal;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 5px;
  text-align: center;
}

.boxFix {
  font-size: 14px;
  width: 80px;
  height: 23px;
}

.testFix {
  margin-top: -8px;
}

@import url(//fonts.googleapis.com/css?family=Teko);

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
  background-color: #f5f5f500 !important;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px !important;
  background-color: #f5f5f500 !important;
}

#moveUp ::-webkit-scrollbar {
  width: 5px;
  height: 10px !important;
  background-color: #c7050500 !important;
}

::-webkit-scrollbar-thumb {
  margin-top: 1em;
  border-radius: 10px;
  height: 30px !important;
  width: 20px !important;
  background-color: rgba(22, 76, 83, 0.404) !important;
}
